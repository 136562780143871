import React, {useState, useEffect, useCallback} from 'react'
import Footer from '../components/footer';
import { FrontEndUrl, PictureUrl, RegisterUrl, ServerUrlAdmin } from '../components/include';

import {Alerts, closeModal, ErrorModal, LabelWarning, LoadingModal} from '../components/notify'
import {ServerUrl, ServerImagesUrl, config} from '../components/include'
import axios from 'axios'
import Cookies from 'js-cookie'
import {encrypt} from '../components/authentication'
import { generateCardNo } from '../components/globalFunction';

const Login =()=> {
	const [notice, setNotice] = useState({
                isLoading : false,
                isDataFetching: false
                });
                const [settings, setSettings] = useState([])
                const [activeStep, setActiveStep] = useState(1);
                
                const [isSubmitting, setIsSubmitting] = useState(false);
                const [user, setUser] = useState({
                        onlineID:'',
                        password:'',
                        otp:'',
                        sentOtp:generateCardNo(4),
                        jwt:'',
                        code:'',
                        email:'',
                        remember:false,
                        date:new Date().toISOString().slice(0,10),
                        dateTime:new Date().toISOString().slice(0,19)
            })
            const [errors, setErrors] = useState({
                        onlineID:'',
                        password:'',
                        otp:'',
            });
        


              

    const fetchSettings =()=>{
       
        var sql ="Select * from tbl_settings " ;
        const fd = new FormData();
        fd.append("sql", sql);
       // fd.append("jwt", Token);
        let url = ServerUrlAdmin+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setSettings(result.data[0])
        })
        .catch((error)=>{
           // setErrors({...errors, errorMessage: error.message})
        })

  }


            const handleChange = event =>{		
                let {name, value} = event.target;	
                      
               setUser({...user, [name] : value });
               setErrors({...errors, [name]:''});
            }

            const handleSubmit = event =>{
                event.preventDefault();
                let formValid = true;
        let errorList ={...errors}
        let msg = 'This field is required';

        if(!user.onlineID){
            formValid = false
            errorList.onlineID = msg
        }

        if(!user.password){
          formValid = false
          errorList.password = msg
      }

      setErrors(errorList)       
           if(formValid){
            submit()
           }
           
        }


       
                
      const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep])
          


    const ConfirmCode=()=>{
      if(!user.otp){
        setErrors({...errors, otp:'Please enter verification code received'})
      }else if(user.otp!==user.sentOtp){
        setErrors({...errors, otp:'You have enter a wrong code'})
      }else if(user.otp===user.sentOtp){

              var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
              Cookies.set('hsbcspringeqrbjt', encrypt(user.jwt),  {expires: inOneHours }) 
              Cookies.set('hsbcspringeqrbsc', encrypt(user.code),  {expires: inOneHours })  
              Cookies.set('hsbcspringeqrbmal', encrypt(user.email),  {expires: inOneHours })  
                Alerts('Welcome!', 'success', 'You have successfully login')

                window.location.href='/home'
                //window.open('/home', '_self')
      }

    }
                function submit(){  
                      LoadingModal()
                let formData = user;
                const fd = new FormData();
                for(let k in formData){
                  fd.append(k, formData[k])
                }
                   let url = ServerUrl+'/login_controller.php?tablename=tbl_login_user';
                        axios.post(url, fd, config)
                        .then(response =>{
                if (response.data.type ==='success'){
                                closeModal()

                                if(settings.client2Fa==='true'){
                  setUser({...user, jwt:response.data.jwt, code:response.data.code, email:response.data.email})
              handleOnClickNext()
                                }else{
                                  
                                  //1000 * 60 * SESSION_IDEL_MINUTES
                              var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
                                  
        
                                Cookies.set('hsbcspringeqrbjt', encrypt(response.data.jwt),  {expires: inOneHours }) 
                                Cookies.set('hsbcspringeqrbsc', encrypt(response.data.code),  {expires: inOneHours })  
                                
                             Cookies.set('hsbcspringeqrbmal', encrypt(response.data.email),  {expires: inOneHours }) 


                                Alerts('Welcome!', 'success', response.data.message)
              
                                window.location.href='/home'
                              //  window.open('/home', '_self') 
                                
                              } 
                 }else if (response.data.type ==='error'){
                                  ErrorModal('Error', response.data.message)
                                 // Alerts('Error!', 'error', 'Login ID Incorrect')
                                       //alert('Login ID incorrect')
                                }    
                        })
                        .catch((error)=>{
                          ErrorModal('Error', error.message)
                                //alert(error.message)
                                 
                        }).finally(()=>{
                    setIsSubmitting(false)
                    setNotice({...notice, 
                                        isLoading: false
                                }) 
                })     
        }
        
        
        
        const clearCookies=()=>{
        
                 Cookies.remove('hsbcspringeqrbjt')	
          Cookies.remove('hsbcspringeqrbsc')
          Cookies.remove('hsbcspringeqrbmal')
        }
        
        useEffect(()=>{  
          clearCookies()
          fetchSettings()
        },[]);
        

    return (<div>
           
<header className="header-area">
<nav className="navbar navbar-expand-lg main-menu">
    <div className="container">

       <div className="logo">
            <a href={FrontEndUrl} style={{cursor: 'pointer',color: '#fff'}}>
                      <div className="icon-logo">
                        <i className="fa fa-university" style={{color: '#fff'}}></i>
                    <span style={{color: '#fff'}}> NB</span>
                      </div>
                    <span>Spare Bank </span>
            </a>
        </div>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="menu-toggle"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto">
                <li className="nav-item dropdown">
                    <a className="nav-link" href={FrontEndUrl} role="button" >Home</a>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Dashboard</a>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="/home">Dashboard</a></li>
                        <li><a className="dropdown-item" href="/customer/edit_profile">Profile</a></li>
                        
                        <li><a className="dropdown-item" href="/customer/previous">Transection Log</a></li>
                        
                        <li><a className="dropdown-item" href="#">Support </a></li>
                    </ul>
                </li>
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Pages</a>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="/">Login</a></li>
                        <li><a className="dropdown-item" href="#">Signup</a></li>
                        <li><a className="dropdown-item" href="#">Privacy</a></li>
                        <li><a className="dropdown-item" href="#">Terms and Services</a></li>
                    </ul>
                </li>
                  <li>
                    <div id="google_translate_element"></div>


                </li> 
            </ul>
           
                 
            
            <div className="header-btn justify-content-end">
                <a href="/home" className="bttn-small btn-emt"><i className="ti-crown"></i>Dashboard</a>
            </div>
        </div>
    </div>
</nav>
</header>

<section className="hero-section">
<div className="hero-area wave-animation">
    <div className="single-hero gradient-overlay">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-5 col-lg-5 col-md-6 col-sm-10 centered">
                    <div className="hero-sub">
                        <div className="table-cell">


                          {activeStep===1?  <div className="hero-left" >
                                <h2 style={{color: '#fff'}}>Login here</h2>
                                <div className="account-form" id="logindiv">
                         <form action='' method="POST" className="row">
                                       <div className="col-xl-12">  
                                        <h5></h5></div>
                                        <div className="col-xl-12">
                <input type="text"  name="onlineID"   value={user.onlineID} placeholder="Online Access" onChange={handleChange} required />
                <label  className="error" style={{color:'white'}} >{ errors.onlineID}</label> 
                                        </div>
                                        <div className="col-xl-12">
     <input type="password" name="password" placeholder="Password"  value={user.password}  onChange={handleChange} required /> 
     <label  className="error" style={{color:'white'}} >{ errors.password}</label>
                                        </div>
                                      
                                        <div className="col-xl-12">
                         <button type="submit" name="loginbt" onClick={handleSubmit} className="bttn-mid btn-fill w-100">Login now</button>
                                        </div>
                                    </form>
                                    <div className="extra-links">
                                        <a href="#">Forgot password</a>
                                        <a href={RegisterUrl} target="_blank">Register now</a>
                                    </div>
                                </div>
                                </div>:


                                <div className="hero-left" id="token" >
                                <h2 style={{color: '#fff'}}>Verify Token</h2>
                                <div className="account-form"  >
                                    <form action='' method="POST" className="row">
                                       <div className="col-xl-12">  
                                        <h5></h5></div>
                                        
                                        <div className="col-xl-12">
                        <label for="token">Enter token key sent to your email</label>
         <input type="text" id="token" name="otp"   value={user.otp} placeholder="OTP" onChange={handleChange} required />


     <label  className="error" style={{color:'white'}} >{ errors.otp}</label>
                                        </div>
                                        
                                        
                                        <div className="col-xl-12">
 <button type="button" name="logi" className="bttn-mid btn-fill w-100" onClick={ConfirmCode}>Verify</button>
                                        </div>
                                    </form>
                                    <div className="extra-links">
                                        <a href="#">Resend token</a>
                                        <a href="#">logout</a>
                                        
                                    </div>
                                </div>
                               </div> }



                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
</section>
<footer className="footer-area section-padding-2 blue-bg wave-animation">
<div className="container">
    <div className="row">
        <div className="col-xl-12 centered cl-white copyright mt-50">
            <p className="mb-0" >Copyright &copy; 2022 All Rights Reserved || NB Spare  Bank </p>
        </div>
    </div>
</div>
</footer>   
</div>
);
       
}

export default React.memo(Login)  
