import React, {useState, useEffect} from 'react'
import Template from '../components/template';

import { customerCode, ServerUrlAdmin, config, token, email, emailAddress, ServerUrl } from '../components/include';

import { Alerts, closeModal, LoadingModal } from '../components/notify';
import axios from 'axios'

const Transfer =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});


    const [users, setUsers] = useState([])
	const [isSubmitting, setIsSubmitting] = useState(false);
		
    //customerCode, accountNumber, taxPin, authorCode, taCode, InsuranceCode, frCode,
    var Initials = {
        code:Math.random().toString(36).substr(2,9),
		bankIPOS:'NetBanking Adminstrator',
		amount:'',
        bankName:'',
        status:'Processing',
        bankAccount:'',
        benefName:'',
        swiftCode:'',
        email:email,
        customerCode:customerCode,
        accountNumber:'',
        taxPin:'',
        authorCode:'',
        taCode:'',
        InsuranceCode:'',
        frCode:'',
        transferDate:'0000-00-00',
        isTransfer:true,
        isAuthorize :false,
        isTaxPin:false, 
        isTaCode:false, 
        IsinsuranceCode:false, 
        isFrCode:false,
        isTransferOld:false,
        message:'',
        ref:''

    }
	const [transferMoney, setTransferMoney] = useState(Initials)
    
    const [settings, setSettings] = useState([])
    const [errors, setErrors] = useState({});

    const fetchSettings =()=>{
       
        var sql ="Select * from tbl_settings " ;
        const fd = new FormData();
        fd.append("sql", sql);
       // fd.append("jwt", Token);
        let url = ServerUrlAdmin+'/fetchBySql_controller.php';
        
        axios.post(url, fd, config).then(result=>{  
            setSettings(result.data[0])
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
        })

  }


    const handleChange = event =>{		
        let {name, value} = event.target;	
        setTransferMoney({...transferMoney, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleSubmit = event =>{
        event.preventDefault();
         

        window.scrollTo({top:0, left:0, behavior:'smooth'})
  
        let errors = {};  

        if(!transferMoney.bankName){
            errors.bankName ='Please fill out this field';
        }
       
        if(!transferMoney.benefName){
            errors.benefName ='Please fill out this field';
        }
        if(!transferMoney.bankAccount){
            errors.bankAccount ='Please fill out this field';
        }
        if(!transferMoney.swiftCode){
            errors.swiftCode ='Please fill out this field';
        }
        if(!transferMoney.amount){
            errors.amount ='Please fill out this field';
        }

       
        if(transferMoney.amount && Number(transferMoney.amount)<100){
            errors.amount ='You cannot transfer less than 100';
            alert('You cannot transfer less than 100')
        }

        if(!transferMoney.amount){
            errors.amount ='Please fill out this field';
        }
        if(transferMoney.transferDate ==='0000-00-00'){
            errors.transferDate ='Please fill out this field';
        }
        setErrors(errors)


        if(Object.keys(errors).length === 0 && settings.isTransferOld==='true'){

        submit()
        }else if(Object.keys(errors).length === 0 && settings.isTransferOld==='false'){
            submit()
            setTransferMoney({...transferMoney, isTransfer:false, isAuthorize:true})
        }
        }

	
	


 function UpdateCode(input, code, column){  

    if(input ===''){
        alert('The code field is required')
        setErrors({...errors, [column]:'The code field is required'})
    }else{
    
    if(input === code && column ==='authorCode'){
       setTransferMoney({...transferMoney, isAuthorize:false, isTaxPin:true}) 
    }else if(input === code && column ==='taxPin'){
        setTransferMoney({...transferMoney, isTaxPin:false, isTaCode:true}) 
    }else if(input === code && column ==='taCode'){
        setTransferMoney({...transferMoney,  isTaCode:false, IsinsuranceCode:true}) 

    }else if(input === code && column ==='InsuranceCode'){
        setTransferMoney({...transferMoney,  IsinsuranceCode:false, isFrCode:true}) 
     }else if(input === code && column ==='frCode'){
        setTransferMoney({...transferMoney,  isFrCode:false,  isTransferOld:true}) 
     }else{
        window.scrollTo(0,0)
        alert('You have entered a wrong code')
        setErrors({...errors, [column]:'You have entered a wrong code'})
     }
  
    
  }

 }

const completeTransfer =()=>{

    LoadingModal()
    setNotice({...notice,   isLoading: true})     
    
  let formData = transferMoney;
  const fd = new FormData();
  for(let k in formData){
  fd.append(k, formData[k])
  }

  fd.append('accountBalance', users.accountBalance)
  fd.append('sendMail', settings.emailAccount)
  fd.append('status', 'Completed')

  let url = ServerUrlAdmin+'/save_controller.php?tablename=tbl_transfer_money_complete';
  axios.post(url, fd, config)
  .then(response =>{
  if(response.data.type === 'success'){
    
closeModal()
    Alerts('Info!', 'default', response.data.message)

//window.open(ServerUrlAdmin+"/printout/index.php?token="+token+"&ref="+response.data.ref, '_blank')

    //goto printout page here
      setTransferMoney({...transferMoney, ref:response.data.ref, message:response.data.message})
            } else{
                closeModal()
          window.scrollTo(0,0)
          alert(JSON.stringify(response.data, null, 2))
            }   
        })
        .catch((error)=>{
            alert(error.message)
        }).finally(()=>{
          
          setNotice({...notice, 
            isLoading: false
          })
          setIsSubmitting(false);
        })

}


 function submit(){  


   setNotice({...notice, 
                  isLoading: true})     
              
            let formData = transferMoney;
            const fd = new FormData();
            for(let k in formData){
            fd.append(k, formData[k])
            }
            fd.append('status', 'Processing')
            let url = ServerUrlAdmin+'/save_controller.php?tablename=tbl_transfer_money';
            axios.post(url, fd, config)
            .then(response =>{
            if(response.data.type === 'success'){
                setTransferMoney({...transferMoney, isTransfer:false, isAuthorize:true})
                      } else{
                 
                    window.scrollTo(0,0)
                    alert(JSON.stringify(response.data, null, 2))
                      }   
                  })
                  .catch((error)=>{
                      alert(error.message)
                  }).finally(()=>{
                    
                    setNotice({...notice, 
                      isLoading: false
                    })
                    setIsSubmitting(false);
                  }) 
              }

             
    const fetchContent =()=>{
        if(customerCode!=='null'){
    
        var sql ="Select p.customerCode, p.accountNumber, p.taxPin, p.authorCode, p.taCode, p.InsuranceCode, p.frCode, c.accountBalance from tbl_pin p, tbl_customers c where c.code = p.customerCode and p.customerCode ='"+customerCode+"'";
         
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrlAdmin+'/fetchBySql_controller.php'
        
        axios.post(url, fd, config).then(result=>{ 
            if(result.data.length!==0){
                setUsers(result.data[0])
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
         
        })
        
        }
    }

    
useEffect(()=>{
    fetchSettings()
    fetchContent() 
     },[]);

    return (<Template 
    title='Transfer Funds'>
    

{transferMoney.isTransfer?
    <div className="col-lg-7 col-md-7 col-xs-12">
<div className="inner-box my-resume">
        
        <div className="work-experence item">
                <h3>Transfer Funds</h3>

                
                    <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-12 col-xs-12">
                    <div className="add-resume box">
                        
                        
                                                                        
                        <br/><br/>
                        
                        <div className="head">
                            
                            <h3 style={{color: '#d51224'}}>No Benefeciary Added with your account.</h3></div>
                            
                            <form className="form-ad" action="transfer_presuccess1" method="POST">
                                
                                <h3 style={{color: '#26ae61', fontWeight:'bold'}}>International Transfers</h3>
                                
                                <div className="form-group">
                                    <label className="control-label" style={{color: '#d51224', fontWeight:'bold'}}>Bank Name</label>
                                    <input type="text" name="bankName" value={transferMoney.bankName} onChange={handleChange} className="form-control" />

                                    <label  className="validation-invalid-label" >{ errors.bankName}</label>
                    </div>
                    
                    <div className="form-group">
                        
                        <label className="control-label" style={{color: '#d51224', fontWeight:'bold'}}>Beneficiary Name </label>
                        <input type="text" name="benefName" value={transferMoney.benefName} onChange={handleChange} className="form-control" />

<label className="validation-invalid-label" >{ errors.benefName}</label>
                    </div>
                    
                    <div className="form-group"><label className="control-label"></label>
                    
                    <label className="control-label" style={{color: '#d51224', fontWeight:'bold'}}>Account Number</label>
                    
                    <input type="text" name="bankAccount" value={transferMoney.bankAccount} onChange={handleChange} className="form-control" />

<label className="validation-invalid-label" >{ errors.bankAccount}</label>
                    </div>
                    
                    <div className="form-group">
                        
                        <label className="control-label" style={{color: '#d51224', fontWeight:'bold'}}>Swift Code</label>
                        <input type="text" name="swiftCode" value={transferMoney.swiftCode} onChange={handleChange} className="form-control" />

<label className="validation-invalid-label" >{ errors.swiftCode}</label>
                    </div>
                    <div className="form-group">
                        <label className="control-label" style={{color: '#d51224', fontWeight:'bold'}}>Amount: USD</label>
                        <input type="number" name="amount" value={transferMoney.amount} onChange={handleChange} className="form-control" />

<label className="validation-invalid-label" >{ errors.amount}</label>
                    </div>
                    <div className="form-group">
                        <label className="control-label" style={{color: '#d51224', fontWeight:'bold'}}>Execution Date</label>
                        <input type="date" name="transferDate" value={transferMoney.transferDate} onChange={handleChange} className="form-control" />

<label className="validation-invalid-label" >{ errors.transferDate}</label>
                    </div>
                    
                    <input type="button" name="submitBtn" onClick={handleSubmit} className="btn btn-common" value="Confirm" />	</form>
                    </div> 												
                    </div>
                    </div>
                    </div>
                                                        
                                </div>
                                                                    



                            </div>:
                            transferMoney.isAuthorize&& settings.isAuthorize==='true'?


            <div className="col-lg-7 col-md-7 col-xs-12">
            <div className="inner-box my-resume">
                    
                    <div className="work-experence item">
                            <h3>Authorization Code</h3>

                            
                                <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-12 col-xs-12">
                                <div className="add-resume box">
                                    
                                    <form className="form-ad" >
                                    
                                <div className="form-group">
                                <label className="control-label">Authorization Code </label>
                               
                                <input type="text" name="authorCode" value={transferMoney.authorCode} onChange={handleChange} className="form-control" />

<label className="validation-invalid-label" >{ errors.authorCode}</label>
                                </div>
                                
                                <div className="form-group">
                                <label className="control-label"></label>
                                
                                <input hidden="text" required="" className="form-control" name="name" value="Bu" />
                                </div>
                                <div className="form-group">
                                <label className="control-label"></label>
                                
                                <input hidden="text" required="" className="form-control" name="no" value="23" />
                                </div>
                                <div className="form-group">
                            
                                <input hidden="text" required="" name="transfer" className="form-control" value="4750200.00" />
                                </div>
                                <div className="form-group">
                            
                                <input hidden="text" required="" className="form-control" name="t_val" value="340" />
                                </div>
                            
                                &nbsp;<br/><span style={{color:'red'}}></span>
                                


                     <input type="button" onClick={()=>UpdateCode(transferMoney.authorCode, users.authorCode, 'authorCode')} name="submitBtn" className="btn btn-common" value="Confirm" />



                        
                                
                            </form>
                            
                            
                                </div>
                                </div>
                                </div>
                                                                    
                                            </div>
                                                                                



                                        </div>
                                </div>:
                                
                                transferMoney.isTaxPin===true&& settings.isTaxPin==='true'?       
            <div className="col-lg-7 col-md-7 col-xs-12">
            <div className="inner-box my-resume">
                    
                    <div className="work-experence item">
                            <h3>Tax Pin </h3>

                            
                                <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-12 col-xs-12">
                                <div className="add-resume box">
                                    
                                    <form className="form-ad" >
                                    
                                <div className="form-group">
                                <label className="control-label">Tax Pin</label>
                               
                                <input type="text" name="taxPin" value={transferMoney.taxPin} onChange={handleChange} className="form-control" />

<label className="validation-invalid-label" >{ errors.taxPin}</label>
                                </div>
                                
                                <div className="form-group">
                                <label className="control-label"></label>
                                
                                <input hidden="text" required="" className="form-control" name="name" value="Bu" />
                                </div>
                            
                                &nbsp;<br/><span style={{color:'red'}}></span>
                                


                         <input type="button" onClick={()=>UpdateCode(transferMoney.taxPin, users.taxPin, 'taxPin')} name="submitBtn" className="btn btn-common" value="Confirm" />
                            </form>
                            
                            
                                </div>
                                </div>
                                </div>
                                                                    
                                            </div>
                                                                                



                                        </div>
                                </div>
                                :
                                transferMoney.isTaCode===true&&  settings.isTaCode==='true'?       
                                <div className="col-lg-7 col-md-7 col-xs-12">
                                <div className="inner-box my-resume">
                                        
                                        <div className="work-experence item">
                                                <h3>Transfer Access Code  </h3>
                    
                                                
                                                    <div className="row justify-content-center">
                                                    <div className="col-lg-9 col-md-12 col-xs-12">
                                                    <div className="add-resume box">
                                                        
                                                        <form className="form-ad" >
                                                        
                                                    <div className="form-group">
                                                    <label className="control-label">Transfer Access Code </label>
                                                   
                                                    <input type="text" name="taCode" value={transferMoney.taCode} onChange={handleChange} className="form-control" />
                    
                    <label className="validation-invalid-label" >{ errors.taCode}</label>
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                    <label className="control-label"></label>
                                                    
                                                    <input hidden="text" required="" className="form-control" name="name" value="Bu" />
                                                    </div>
                                                
                                                    &nbsp;<br/><span style={{color:'red'}}></span>
                                                    
                    
                    
                    <input type="button" onClick={()=>UpdateCode(transferMoney.taCode, users.taCode, 'taCode')} name="submitBtn" className="btn btn-common" value="Confirm" />
                                                </form>
                                                
                                                
                                                    </div>
                                                    </div>
                                                    </div>
                                                                                        
                                                                </div>
                                                                                                    
                    
                    
                    
                                                            </div>
                                                    </div>:
 transferMoney.IsinsuranceCode===true&& 
settings.isInsuranceCode==='true'?       
                                <div className="col-lg-7 col-md-7 col-xs-12">
                                <div className="inner-box my-resume">
                                        
                                        <div className="work-experence item">
                                                <h3>Insurance Code </h3>
                    
                                                
                                                    <div className="row justify-content-center">
                                                    <div className="col-lg-9 col-md-12 col-xs-12">
                                                    <div className="add-resume box">
                                                        
                                                        <form className="form-ad" >
                                                        
                                                    <div className="form-group">
                                                    <label className="control-label">Insurance Code  </label>
                                                   
                                                    <input type="text" name="InsuranceCode" value={transferMoney.InsuranceCode} onChange={handleChange} className="form-control" />
                    
                    <label className="validation-invalid-label" >{ errors.InsuranceCode}</label>
                                                    </div>
                                                    
                                                    <div className="form-group">
                                                    <label className="control-label"></label>
                                                    
                                                    <input hidden="text" required="" className="form-control" name="name" value="Bu" />
                                                    </div>
                                                
                                                    &nbsp;<br/><span style={{color:'red'}}></span>
                                                    
                    
                    
     <input type="button" onClick={()=>UpdateCode(transferMoney.InsuranceCode, users.InsuranceCode, 'InsuranceCode')} name="submitBtn" className="btn btn-common" value="Confirm" />
                                                </form>
                                                
                                                
                                                    </div>
                                                    </div>
                                                    </div>
                                                                                        
                                                                </div>
                                                                                                    
                    
                    
                    
                                                            </div>
                                                    </div>
:transferMoney.isFrCode===true&& settings.isFrCode==='true'?       
<div className="col-lg-7 col-md-7 col-xs-12">
<div className="inner-box my-resume">
        
        <div className="work-experence item">
                <h3>Federal Regulation Code </h3>

                
                    <div className="row justify-content-center">
                    <div className="col-lg-9 col-md-12 col-xs-12">
                    <div className="add-resume box">
                        
                        <form className="form-ad" >
                        
                    <div className="form-group">
                    <label className="control-label">Federal Regulation Code </label>
                   
                    <input type="text" name="frCode" value={transferMoney.frCode} onChange={handleChange} className="form-control" />

<label className="validation-invalid-label" >{ errors.frCode}</label>
                    </div>
                    
                    <div className="form-group">
                    <label className="control-label"></label>
                    
                    <input hidden="text" required="" className="form-control" name="name" value="Bu" />
                    </div>
                
                    &nbsp;<br/><span style={{color:'red'}}></span>
                    


<input type="button" onClick={()=>UpdateCode(transferMoney.frCode, users.frCode, 'frCode')} name="submitBtn" className="btn btn-common" value="Confirm" />
                </form>
                
                
                    </div>
                    </div>
                    </div>
                                                        
                                </div>
                                                                    



                            </div>
                    </div>:

settings.isTransferOld==='true'?       
<div className="col-lg-7 col-md-7 col-xs-12">
<div className="inner-box my-resume">
        
        <div className="work-experence item">
                <h3 style={{color:'red'}}>TRANSFER ON HOLD, please contact your bank at {emailAddress} </h3>

                
                                                        
                                </div>
                                                                    



                            </div>
                    </div>:
                              <div className="col-md-4">
                                 
                              <div className={{height:'100px' }}></div>


                              {transferMoney.ref!==''? <div>
                              <h3 style={{color:'green'}}>{transferMoney.message}</h3>

                              {transferMoney.ref!==''?<a href={ServerUrl+"/printout/index.php?token="+token+"&ref="+transferMoney.ref} target="_blank" className='btn btn-common'> Print Receipt</a>:''}
</div>:<div>



{notice.isLoading? <button type="button" className="submit"  disabled={true} ><span className='loader'></span> ... Please wait</button>

                     : <input type="button" name="submitBtn" onClick={completeTransfer} className="submit" value="Click to Complete Transfer" />
}

</div>}
                          </div>   }

                                 
</Template>
);
       
}

export default React.memo(Transfer)  
